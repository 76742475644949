import { memo, useEffect, useMemo, useState } from 'react'

import {
  INITIAL_PAGINATION_RESPONSE,
  useUseGetWorkflowSubscriptionInsightQueryQuery,
} from '@shared/api/rtkQuery'
import deleteEmptyKeys from '@shared/lib/utils/deleteEmptyKeys'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import { DataTable } from '@shared/ui/table'
import {
  useSetSharedState,
  useSharedState,
} from '@widgets/insight/lib/SharedStateContext'

import columns from './columns'

const WorkflowInsightWithSubscriptionDataTable = () => {
  const sharedState = useSharedState()
  const setSharedState = useSetSharedState()
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)

  const { data = INITIAL_PAGINATION_RESPONSE, isLoading } =
    useUseGetWorkflowSubscriptionInsightQueryQuery(
      deleteEmptyKeys({
        start: sharedState.timeRangeParams[0] as string,
        end: sharedState.timeRangeParams[1] as string,
        name: sharedState.searchText,
        page,
        perPage,
      })
    )

  useEffect(() => setPage(DEFAULT_PAGE), [perPage])
  useEffect(() => {
    setSharedState(prev => ({ ...prev, isLoading: isLoading }))
  }, [isLoading, setSharedState])

  const tableColumns = useMemo(() => columns(), [])

  return (
    <DataTable
      columns={tableColumns}
      rows={data.items}
      count={data.totalCount}
      page={page - 1}
      rowsPerPage={perPage}
      isLoading={isLoading}
      onPageChange={newPage => {
        setPage(newPage + 1)
      }}
      onRowsPerPageChange={setPerPage}
    />
  )
}

export default memo(WorkflowInsightWithSubscriptionDataTable)
