function deleteEmptyKeys<T extends Record<string, unknown | undefined>>(
  obj: T
): T {
  const result = { ...obj }

  Object.keys(result).forEach(key => {
    const value = result[key]

    if (Array.isArray(value) && !value.length) {
      delete result[key]
      return
    }

    if (
      typeof value === 'object' &&
      value &&
      !Object.keys(value as Record<string, unknown>).length
    ) {
      delete result[key]
      return
    }

    if (value == null || value === '') {
      delete result[key]
      return
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      result[key as keyof T] = deleteEmptyKeys(
        value as Record<string, unknown>
      ) as T[keyof T]
    }
  })

  return result
}

export default deleteEmptyKeys

export const recursiveDeleteEmptyKeys = <
  T extends Record<string, unknown | undefined>
>(
  obj: T
): T => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  let result = { ...obj }
  let previousResult: T

  do {
    previousResult = result
    result = deleteEmptyKeys(result)
  } while (JSON.stringify(previousResult) !== JSON.stringify(result))

  return result
}
