import { memo } from 'react'
import Box from '@mui/material/Box'

import theme from '@theme'

import BasicInput, { Props } from './BasicInput'

const SearchBar = ({ defaultValue, placeholder, onSearch }: Props) => (
  <Box
    key={defaultValue}
    sx={{
      form: {
        display: 'flex',
        alignItems: 'center',
        width: '280px',
        boxShadow: `0px 4px 9px ${theme.colors.black6}`,
      },
      input: {
        pl: 2,
        width: '100%',
        height: 42,
        border: 'none',
        borderRadius: ' 4px',
        backgroundColor: theme.colors.white,
        fontSize: '13px',
        '&::placeholder': {
          color: theme.colors.textSecondBlue,
        },
        '&:focus': {
          outline: 'none',
        },
      },
      button: {
        width: '48px',
        height: '42px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: theme.colors.white,
        cursor: 'pointer',
        '&:focus': {
          outline: 'none',
        },
      },
    }}
  >
    <BasicInput
      defaultValue={defaultValue}
      placeholder={placeholder}
      onSearch={onSearch}
    />
  </Box>
)

export default memo(SearchBar)
