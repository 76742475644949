import { memo, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  getConversionsInsight,
  INITIAL_PAGINATION_RESPONSE,
  useGetCampaignPreviewStatisticSummaryQuery,
  useGetCampaignsListQuery,
} from '@shared/api/rtkQuery'
import { DotLoader } from '@shared/ui/loaders'
import {
  useSetSharedState,
  useSharedState,
} from '@widgets/insight/lib/SharedStateContext'

import useConversionDisplayName from '../useConversionDisplayName'
import Table from './Table'

type Props = {
  id: number
  conversionEventEnable: boolean
  conversionEvent: string
  conversionEventField: string
}

const MediumTable = ({
  id,
  conversionEventEnable,
  conversionEvent,
  conversionEventField,
}: Props) => {
  const { t } = useTranslation(['insight'])
  const sharedState = useSharedState()
  const setSharedState = useSetSharedState()
  const {
    data: campaignList = INITIAL_PAGINATION_RESPONSE,
    isLoading: isCampaignLoading,
    isSuccess: isCampaignSuccess,
  } = useGetCampaignsListQuery({
    // Pagination parameter:  No effect for trigger_group_type or trigger_type
    triggerGroupId: id,
    triggerGroupType: 'workflow',
  })

  const { conversionValueDisplayName, conversionEventDisplayName } =
    useConversionDisplayName({
      conversionEventEnable,
      conversionEvent,
      conversionEventField,
    })

  const conversion = useMemo(
    () =>
      getConversionsInsight({
        conversionEventEnable,
        conversionEvent,
        conversionEventField,
      }),
    [conversionEventEnable, conversionEvent, conversionEventField]
  )

  const {
    emailCampaignInsightList,
    smsCampaignInsightList,
    lineCampaignInsightList,
    isDetailInsightLoading,
    isDetailInsightFetching,
    isDetailInsightSuccess,
  } = useGetCampaignPreviewStatisticSummaryQuery(
    {
      filterParams: {
        sourceIds: campaignList.items.map(({ id }) => id),
        groupByFields: [
          {
            source: 'campaign_events',
            field: 'third_party_app_integration_type',
          },
        ],
        timeRangeParams: sharedState.timeRangeParams,
        ...conversion,
      },
    },
    {
      skip: !isCampaignSuccess || !campaignList.items.length,
      selectFromResult: ({
        data = INITIAL_PAGINATION_RESPONSE,
        isFetching,
        isLoading,
        isSuccess,
      }) => {
        const emailCampaignInsightList = data.items.filter(insight =>
          insight.mediumType?.includes('email')
        )

        const smsCampaignInsightList = data.items.filter(insight =>
          insight.mediumType?.includes('sms')
        )

        const lineCampaignInsightList = data.items.filter(insight =>
          insight.mediumType?.includes('line_push')
        )

        return {
          emailCampaignInsightList,
          smsCampaignInsightList,
          lineCampaignInsightList,
          isDetailInsightLoading: isLoading,
          isDetailInsightFetching: isFetching,
          isDetailInsightSuccess: isSuccess,
        }
      },
    }
  )

  const isTableLoading =
    isDetailInsightFetching || isCampaignLoading || isDetailInsightLoading

  useEffect(() => {
    setSharedState(prev => ({
      ...prev,
      isLoading: isTableLoading,
      exportParams: {
        filterParams: {
          sourceIds: campaignList.items.map(({ id }) => id),
          timeRangeParams: sharedState.timeRangeParams,
          groupByFields: [
            {
              source: 'campaign_events',
              field: 'third_party_app_integration_type',
            },
          ],
          ...conversion,
        },
        workflowId: id,
      },
    }))
  }, [
    isDetailInsightSuccess,
    setSharedState,
    id,
    isDetailInsightFetching,
    conversion,
    campaignList.items,
    sharedState.timeRangeParams,
    isTableLoading,
  ])

  if (isCampaignLoading || isDetailInsightLoading) {
    return (
      <Box width="100%" display="flex" justifyContent="center">
        <DotLoader />
      </Box>
    )
  }

  return (
    <>
      {emailCampaignInsightList.length > 0 && (
        <Table
          title={t('insight:email_campaign_summary')}
          mediumType="email"
          data={emailCampaignInsightList}
          isLoading={isTableLoading}
          isConversionEnable={conversionEventEnable}
          conversionEventDisplayName={conversionEventDisplayName}
          conversionValueDisplayName={conversionValueDisplayName}
        />
      )}
      {smsCampaignInsightList.length > 0 && (
        <Table
          title={t('insight:sms_campaign_summary')}
          mediumType="sms"
          data={smsCampaignInsightList}
          isLoading={isTableLoading}
          isConversionEnable={conversionEventEnable}
          conversionEventDisplayName={conversionEventDisplayName}
          conversionValueDisplayName={conversionValueDisplayName}
        />
      )}
      {lineCampaignInsightList.length > 0 && (
        <Table
          title={t('insight:line_campaign_summary')}
          mediumType="line_push"
          data={lineCampaignInsightList}
          isLoading={isTableLoading}
          isConversionEnable={conversionEventEnable}
          conversionEventDisplayName={conversionEventDisplayName}
          conversionValueDisplayName={conversionValueDisplayName}
        />
      )}
    </>
  )
}

export default memo(MediumTable)
