import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetWorkflowPreviewStatisticSummaryExportMutation } from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { trackEvent } from '@shared/lib/utils/amplitude'
import { openToast } from '@shared/model/slices'
import { useSharedState } from '@widgets/insight/lib/SharedStateContext'
import { downloadInsightCSV } from '@widgets/insight/lib/utils/utils'

import Head from '../../_shared/Head'
import WithCampaignDataTable from './WorkflowInsightWithCampaignDataTable'
import WithSubscriptionDataTable from './WorkflowInsightWithSubscriptionDataTable'

const Overview = () => {
  const { t } = useTranslation(['common'])
  const dispatch = useAppDispatch()
  const sharedState = useSharedState()
  const [exportWorkflowPreviewStatisticSummary, { isLoading: isExporting }] =
    useGetWorkflowPreviewStatisticSummaryExportMutation()

  useEffect(() => {
    const [startDate, endDate] = sharedState.timeRangeParams as string[]

    trackEvent('AutomationJourneyPerformanceFiltered', {
      filterEndDate: endDate,
      filterStartDate: startDate,
      filterText: sharedState.searchText,
      filterType: sharedState.filterType,
    })
  }, [sharedState])

  useEffect(() => {
    trackEvent('AutomationJourneyPerformanceViewed')
  }, [])

  const handleExportInsight = async () => {
    if (!sharedState.exportParams) {
      return
    }

    try {
      const filename = await exportWorkflowPreviewStatisticSummary(
        sharedState.exportParams
      ).unwrap()

      if (!filename || Array.isArray(filename)) {
        throw new Error('Failed to export')
      }

      downloadInsightCSV(filename, 'workflow_insight')
    } catch (_e) {
      dispatch(
        openToast({
          message: t('common:failure_export'),
          status: 'error',
        })
      )
    }
  }

  return (
    <>
      <Head
        title={t('common:route.workflow_insight')}
        hasWorkflowOnlyWithSubscriptionFilter
        onExport={handleExportInsight}
        isExportDisabled={
          sharedState.filterType === 'subscription' || isExporting
        }
      />
      {sharedState.filterType === 'subscription' ? (
        <WithSubscriptionDataTable />
      ) : (
        <WithCampaignDataTable />
      )}
    </>
  )
}

export default Overview
