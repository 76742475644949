import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { DeepBlueGradientButton } from '@shared/ui/buttons'
import { useSharedState } from '@widgets/insight/lib/SharedStateContext'

export type Props = {
  onExport?: () => void
  isExportDisabled: boolean
}

const ExportCSVButton = ({ onExport, isExportDisabled }: Props) => {
  const { t } = useTranslation(['common', 'insight'])
  const sharedState = useSharedState()

  const handleExport = () => {
    onExport?.()
  }

  return (
    <Box ml={3}>
      <DeepBlueGradientButton
        onClick={handleExport}
        width={200}
        disabled={sharedState.isLoading || isExportDisabled}
      >
        {t('common:export')}
      </DeepBlueGradientButton>
    </Box>
  )
}

export default memo(ExportCSVButton)
