import axios from 'axios'

import type {
  InsightSummary,
  MediumType,
  ThirdPartyAppProvider,
} from '@shared/api/rtkQuery'
import { getFloatSafe, getIntSafe } from '@shared/lib/utils/number'
import { getYYYYMMDDHHMMSS } from '@shared/lib/utils/time'
import { NoWrap } from '@widgets/insight/ui/_shared/styles'

type InsightSummaryKey = keyof InsightSummary['statisticSummaries'][number]
export type InsightSummaryValue =
  | InsightSummary['statisticSummaries'][number][InsightSummaryKey]

export const ALL_PROVIDERS = 'allProviders'

export const insightValuesFormatterByProvider = (
  insightValue: {
    value: number | string
    mediumType?: MediumType
    provider: ThirdPartyAppProvider
  },
  option: {
    isPercentage?: boolean
    allowedMedium?: {
      [type in MediumType]?: (ThirdPartyAppProvider | typeof ALL_PROVIDERS)[]
    }
  }
) => {
  const { isPercentage = false, allowedMedium } = option
  const { value, mediumType, provider } = insightValue

  if (!String(value) || String(value) === 'undefined' || !mediumType) {
    return '-'
  }

  const isProviderSupported =
    allowedMedium &&
    (allowedMedium[mediumType]?.includes(ALL_PROVIDERS) ||
      allowedMedium[mediumType]?.includes(provider))

  if (!isProviderSupported) {
    return '-'
  }

  if (isPercentage) {
    return <NoWrap>{`${getFloatSafe(Number(value) * 100).toFixed(2)}%`}</NoWrap>
  }

  return <NoWrap>{getIntSafe(value).toLocaleString()}</NoWrap>
}

export const insightValueFormatter = (
  value: InsightSummaryValue,
  options?: { isPercentage?: boolean }
) => {
  if (!String(value) || String(value) === 'undefined') {
    return `-`
  }

  if (options?.isPercentage) {
    return <NoWrap>{`${getFloatSafe(Number(value) * 100).toFixed(2)}%`}</NoWrap>
  }

  return <NoWrap>{getIntSafe(value).toLocaleString()}</NoWrap>
}

export const downloadInsightCSV = async (
  filename: string,
  downloadName: string
) => {
  const axiosInstance = axios.create()

  axiosInstance.interceptors.request.use(config => {
    const whitelist = [`https://storage.googleapis.com/`]

    const url = config.url ?? ''
    if (!whitelist.some(whitelistedUrl => url.startsWith(whitelistedUrl))) {
      throw new Error('URL not in whitelist')
    }

    return config
  })

  const { data: csvData } = await axiosInstance.get(filename)
  const nowDate = getYYYYMMDDHHMMSS(new Date())

  const blob = new Blob([csvData], {
    type: 'text/csv;charset=utf-8;',
  })
  const $a = document.createElement('a')
  $a.href = window.URL.createObjectURL(blob)
  $a.download = `${downloadName}_${nowDate}.csv`
  document.body.appendChild($a)
  $a.click()
  $a.remove()
}
